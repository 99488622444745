<template>
  <div>
    <el-form label-width="70px"
             :model="form">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       @change="getTree"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="关键字:">

            <el-input placeholder="请输入考点名称"
                      v-model="form.keywords"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="上级考点"
                        label-width="100px">
            <el-cascader v-model="form.parent_id"
                         :options="tree"
                         style="width:100%"
                         filterable
                         :show-all-levels="false"
                         :props="{checkStrictly: true,value:'knowledge_id',
                          label:'knowledge_desc',children:'child'}"
                         clearable></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="add">新增</el-button>
                     <el-button type="primary"
                     @click="importCheckPoint">导入考点</el-button>
        </el-col>
      </el-row>
    </el-form>
    <TreeTable :stripe="true"
               :border="true"
               :data="tableData"
               v-if="tableData.length"
               :tree-type="true"
               :columns="tableColumns"
               :children-prop="config.children"
               :expand-type="false"
               :show-row-hover="true"
               :selection-type="false">

      <template slot="subject_name"
                slot-scope="{row}">
        {{row.subject_name}}
      </template>

      <!-- 星级 -->
      <template slot="star"
                slot-scope="{row}">
        {{row.knowledge_point_star}}
      </template>

      <template slot="opt"
                slot-scope="{row}">
        <el-tooltip effect="dark"
                    content="修改"
                    placement="top-start">
          <img :src="imgs['edit']"
               @click="editRow(row)"
               class="operyion_img">
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="关联题目类别"
                    placement="top-start">
          <img :src="imgs['relation']"
               @click="relationTopicType(row)"
               class="operyion_img">
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="删除"
                    placement="top-start">
          <img :src="imgs['del']"
               @click="del(row)"
               class="operyion_img">
        </el-tooltip>
      </template>
    </TreeTable>

    <Form ref="FormRef" />
    <EditForm ref="EditFormRef" />
    <TopicTypeForm ref="topicFormRef" />
    <!-- <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" /> -->
  </div>
</template>

<script>
import Form from './components/form.vue'
import EditForm from './components/editForm.vue'
import TopicTypeForm from './components/topicTypeForm.vue'
export default {
  data () {
    return {
      imgs: {
        del: require('@/assets/icons/icon-删除@2x.png'),
        edit: require('@/assets/icons/icon-修改@2x.png'),
        reset: require('@/assets/icons/icon-重置密码.png'),
        look: require('@/assets/icons/icon-查看@2x.png'),
        fabu: require('@/assets/icons/发布.png'),
        relation: require('@/assets/icons/icon-关联知识点@2x.png'),
      },
      tableColumns: [
        { prop: 'knowledge_desc', align: 'left', label: '考点', width: '450', },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', type: 'template', template: 'subject_name' },
        { prop: 'grade_name', align: 'center', label: '年级', width: '', },
        { prop: 'question_category_name', align: 'center', label: '关联的题目类别', width: '' },

        { prop: 'knowledge_point_star', align: 'center', label: '星级', width: '', type: 'template', template: 'star' },
        { prop: 'knowledge_point_frequency', align: 'center', label: '频率', width: '', },
        { prop: 'error_prone', align: 'center', label: '易错指数', width: '', },
        {
          prop: 'opt', align: 'center', label: '操作', width: '180', type: 'template',
          template: 'opt', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '关联题目类别', type: 'edit', event: 'relationTopicType' },

            { content: '删除', type: 'del', event: 'del' },
          ]
        },
      ],
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        subject_id: '',
        keywords: '',
        parent_id: ''
      },
      config: { hasChildren: 'child', children: 'child' },
      line_model: '',
      gradeList: [],
      tree: []
    }
  },
  components: {
    Form, EditForm, TopicTypeForm
  },
  mounted () {
    this.getSubjectList()
  },
  methods: {
     // 导入考点
     importCheckPoint () {
      let file
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }).then(res => {
        file = res
        let formData = new FormData()
        formData.append('file', file)
        formData.append('sign', this.$getMd5Form(file))
        this.$http({
          url: '/api/v2/knowledge/import ',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          if(this.form.subject_id){
               this.search()
          }
       
        })
      })

    },
    getTree () {
      this.form.parent_id = ''
      this.tree = []
      this.initData()
      if (this.form.subject_id == '') {
        return
      }
      this.$http({
        url: '/api/v2/knowledge/getHaveChildKp',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
        }
      }).then(res => {
        this.tree = res.data
      })
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    initData () {
      if (this.form.subject_id == '') {
        this.$message.warning('请选择科目')
        return
      }
      let params = JSON.parse(JSON.stringify(this.form))
      params.parent_id = params.parent_id.length ? params.parent_id[params.parent_id.length - 1] : ''
      this.$http({
        url: '/api/v2/knowledge/lst',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list
      })
    },
    search () {
      this.initData()
    },
    editRow (row) {
      let form = {
        knowledge_id: row.knowledge_id,
        knowledge_desc: row.knowledge_desc,
        knowledge_point_star: row.knowledge_point_star,
        error_prone: row.error_prone,
        grade_ids: row.grade_id.split(',').map(item => Number(item)),
      }
      this.$refs.EditFormRef.dialogVisible = true
      this.$refs.EditFormRef.form = form

    },
    add () {
      let form = {
        knowledge_parent_id: 0,
        knowledge_desc: '',
        subject_id: '',
        knowledge_point_star: 0,
        error_prone: 3
      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getList()
    },
    del (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/knowledge/del',
          method: 'post',
          data: {
            id: row.knowledge_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    // relationKnowledge (row) {
    //   let form = {
    //     subject_id: row.subject_id,
    //     knowledge_id: row.knowledge_id
    //   }
    //   this.$refs.KnowledgeFormRef.form = form
    //   this.$refs.KnowledgeFormRef.getKnowledgeTree(row.subject_id, row.knowledge_id)
    //   this.$refs.KnowledgeFormRef.dialogVisible = true
    // },
    relationTopicType (row) {
      let form = {
        subject_id: row.subject_id,
        knowledge_id: row.knowledge_id
      }
      this.$refs.topicFormRef.form = form
      this.$refs.topicFormRef.getTopicTypeTree(row.subject_id)
      this.$refs.topicFormRef.dialogVisible = true
    },
    // getgrade () {
    //   this.$http({
    //     url: '/api/v2/chapter/editGrade',
    //     method: 'post',
    //     data: {
    //       chapter_id: 1,
    //       grade_id: 1
    //     }
    //   }).then(res => {
    //     this.$notify({
    //       title: '提示',
    //       message: '修改成功!',
    //       type: 'success'
    //     });
    //     this.search()
    //   })
    // },
    // d第一个参数 是行内数据 第二个参数 显示类型  第三个是赋值类型
    showSelect (row, type, prop) {
      if (row.level != 1) return
      let list = this.tableData.map(item => {
        item.showSubject = false
        item.showStar = false
        if (item.knowledge_id == row.knowledge_id) {
          item[type] = true
          this.line_model = item[prop]
        }
        return item
      })
      this.$set(this, 'tableData', list)
    },
    save (row, type, showType) {
      let form = {
        knowledge_id: row.knowledge_id,
      }
      form[type] = this.line_model

      this.$http({
        url: '/api/v2/knowledge/changeSubject',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        row[showType] = false
        this.search()
      })
    },
    submit (row) {
      let form = {
        knowledge_id: row.knowledge_id,
        serial_no: row.serial_no,
        knowledge_desc: row.knowledge_desc,
        knowledge_point_star: this.line_model
      }
      this.$http({
        url: '/api/v2/knowledge/edit',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.search()
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.line {
  display: flex;
  .el-button {
    margin-left: 5px;
  }
}
.block {
  display: flex;
}
</style>